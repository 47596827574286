import { getResolutionForView } from '../products/constants';

export function ga_download(view, format, comp) {
  if (typeof ga !== 'undefined') {
    var resolution = getResolutionForView(view);
    if (comp) {
      resolution = '600';
    }

    var action = format + '-' + resolution;
    var label = 'Spinner-' + view

    ga('set', 'dimension2', view);
    ga('set', 'dimension3', format);
    ga('set', 'dimension4', resolution);
    ga('send', 'event', 'download', action, label);

    ga('set', 'dimension2', null);
    ga('set', 'dimension3', null);
    ga('set', 'dimension4', null);
  }
}

export function ga_add_product_impressions(ecAction, ecStep, category, action) {
  try {
    var products = $('.product-impression');
    for (var p = 0; p < products.length; ++p) {
      var product = $(products[p]);
      var promotion  = product.data('promo');
      var id         = promotion ? product.data('id')   + '-promo' : product.data('id');
      var name       = promotion ? product.data('name') + '-promo' : product.data('name');
      var price      = product.data('revenue')

      if (typeof ga !== 'undefined') {
        ga('ec:addImpression', {
          'id':     id,
          'name':   name,
          'price':  price
        });
      }

      if (typeof ga !== 'undefined' && promotion != null) {
        ga('ec:addPromo', {
          'id':   promotion,
          'name': promotion
        });
      }
    }
    if (products.length > 0 && typeof ga !== 'undefined') {
      ga('ec:setAction', ecAction, {
        'step': ecStep
      });
      ga('send', 'event', category, action);
    }
  }
  catch(e) {
  }
}

function _find_product_impression(id, promotion) {
  var products = $('.product-impression');
  for (var p = 0; p < products.length; ++p) {
    var product = $(products[p]);
    var promo = product.data('promo');

    if ((product.data('id') === id) && (promo === promotion || typeof(promo) === 'undefined')) {
      return product;
    }
  }
  return null;
}

function _wrap_function_in_timeout(callback, timeout) {
  if (!callback) {
    return null;
  }

  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, timeout || 500);
  return fn;
}

export function ga_selected_product_cart_action(ecAction, ecStep, category, action, transactionId, callback) {
  try {
    var selected = $('.orders-option-row.selected, .subscription-plan-option-row.selected').find('.subscription-radio-input,.product-radio-input');

    if (selected.length > 0) {
      var product = $(selected[0]);
      var id = product.val();
      var productData = product.data('promotion');
      var promotion = (typeof(productData) == 'undefined' || productData == '') ? null : productData;
      var impression = _find_product_impression(id, promotion);

      if (impression) {
        var id    = promotion ? impression.data('id')   + '-promo' : impression.data('id');
        var name  = promotion ? impression.data('name') + '-promo' : impression.data('name');
        var price = impression.data('revenue');

        if (typeof ga !== 'undefined') {
          ga('ec:addProduct', {
            'id':       id,
            'name':     name,
            'price':    price,
            'quantity': 1,
            'coupon':   promotion
          });
        }

        //facebook pixel events too
        if (typeof fbq !== 'undefined') {
          if (action === 'cart-add' || action === 'initial-cart') {
            fbq('track', 'AddToCart', {
              value: price,
              currency: 'USD'
            });
          }
          else if (action === 'purchase') {
            fbq('track', 'InitiateCheckout');
          }
        }

        if (typeof ga !== 'undefined' && promotion) {
          ga('ec:addPromo', {
            'id':   promotion,
            'name': promotion
          });
        }

        var revenue = null;
        if (ecAction === 'purchase') {
          revenue = price;

          if (typeof gtag !== 'undefined') {
            gtag('event', 'conversion', {
              'send_to': 'AW-961247263/0OjiCP_vknsQn_CtygM',
              'value': price,
              'currency': 'USD'
            });
          }

          if (typeof fbq !== 'undefined') {
            fbq('track', 'Purchase', { value: price, currency: 'USD' });
          }
        }

        if (typeof ga !== 'undefined') {
          ga('ec:setAction', ecAction, {
            'id':      transactionId,
            'step':    ecStep,
            'revenue': revenue,
            'coupon':  promotion
          });

          ga('send', 'event', category, action, {
            hitCallback: _wrap_function_in_timeout(callback, 500)
          });
        } else {
          _wrap_function_in_timeout(callback, 500);
        }
      }
    } else {
      if (typeof(callback) == 'function') {
        callback();
      }
    }
  }
  catch(e) {
    // execute the callback even if the analytics code failed
    if (typeof(callback) == 'function') {
      callback();
    }
  }
}

function gtag_report_conversion(send_to) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'conversion', { 'send_to': send_to });
    return false;
  }
}

export function ga_send_event(eventName) {
  if (typeof ga !== 'undefined') {
    var data = eventName.split("/");
    ga('send', 'event', data[0], data[1] || "default");
  }
}

export function ga_unsubscribe() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'ecommerce', 'unsubscribe');
  }
}

export function ga_reactivate_subscription() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'ecommerce', 'reactivate-subscription');
  }
}

export function analytics_signup() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'user', 'signup');
  }

  gtag_report_conversion('AW-961247263/N58bCPujqXsQn_CtygM');

  if (typeof fbq !== 'undefined') {
    fbq('track', 'Lead');
  }
}

export function analytics_facebook_signup() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'user', 'facebook-signup');
  }

  gtag_report_conversion('AW-961247263/N58bCPujqXsQn_CtygM');

  //facebook signups are automatically activated
  if (typeof fbq !== 'undefined') {
    fbq('track', 'CompleteRegistration', { value: 0.00, currency: 'USD' });
  }
}

export function analytics_shutterstock_signup() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'user', 'shutterstock-signup');
  }

  gtag_report_conversion('AW-961247263/N58bCPujqXsQn_CtygM');

  if (typeof fbq !== 'undefined') {
    fbq('track', 'Lead');
  }
}

export function analytics_activated() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'user', 'activated');
  }

  if (typeof fbq !== 'undefined') {
    fbq('track', 'CompleteRegistration', { value: 0.00, currency: 'USD' });
  }
}

export function ga_product_loaded(type) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'load', type, { 'nonInteraction': true });
  }
}

export function ga_product_spinner_event(type) {
  if (typeof ga !== 'undefined') {
    var interaction = { 'nonInteraction': true };
    if (type === 'spun') {
      interaction = null;
    }
    ga('send', 'event', 'spinner', type, interaction);
  }
}

export function ga_remove_lightbox(asset_id) {
  if (typeof ga !== 'undefined') {
    ga('set', 'dimension1', asset_id);
    ga('send', 'event', 'search', 'remove_lightbox');
  }
}

export function ga_download_plugin(where) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'download-plugin', where);
  }
}

export function ga_related_theme(theme_name) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'related_theme', theme_name);
  }
}

export function ga_related_product(asset_id) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'related_product', asset_id);
  }
}

export function ga_related_fg(fg_node) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'related_objects ', fg_node);
  }
}

export function ga_category(category) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'footer_category ', category);
  }
}

export function ga_plugin_download_tracking_error() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_download_tracking_error');
  }
}

export function ga_homepage_view_all_content(where) {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'homepage_view_all_content', where);
  }
}

export function analytics_plugin_demo_osx_installer() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'osx_installer');
  }

  gtag_report_conversion('AW-961247263/uFOLCNzkknsQn_CtygM');
  optimizely_custom_event('installer_clicked');

  if (typeof fbq !== 'undefined') {
    fbq('trackCustom', 'PluginDemoInstallerClicked');
  }
}

export function analytics_plugin_demo_windows_installer() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'windows_installer');
  }

  gtag_report_conversion('AW-961247263/uFOLCNzkknsQn_CtygM');
  optimizely_custom_event('installer_clicked');

  if (typeof fbq !== 'undefined') {
    fbq('trackCustom', 'PluginDemoInstallerClicked');
  }
}

export function analytics_plugin_demo_adobe_installer() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'adobe_installer');
  }

  gtag_report_conversion('AW-961247263/uFOLCNzkknsQn_CtygM');
  optimizely_custom_event('installer_clicked');

  if (typeof fbq !== 'undefined') {
    fbq('trackCustom', 'PluginDemoInstallerClicked');
  }
}

export function analytics_plugin_demo_start_demo() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'start_demo');
  }

  gtag_report_conversion('AW-961247263/gM91CKLdknsQn_CtygM');
  optimizely_custom_event('demo_started');

  if (typeof fbq !== 'undefined') {
    fbq('trackCustom', 'PluginDemoStarted');
  }
}

export function analytics_plugin_demo_select_object() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'select_object');
  }

  optimizely_custom_event('demo_object_selected');
}

export function analytics_plugin_demo_spin_object() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'spin_object');
  }

  optimizely_custom_event('demo_object_spun');
}

export function analytics_plugin_demo_end_demo() {
  if (typeof ga !== 'undefined') {
    ga('send', 'event', 'plugin_demo', 'end_demo');
  }

  optimizely_custom_event('demo_completed');
}

function optimizely_custom_event(name) {
  if (typeof window.optimizely !== 'undefined') {
    window['optimizely'] = window['optimizely'] || []
    window.optimizely.push(['trackEvent', name]);
  }
}


$(function() {
  $('[data-ga-click-event]').on('click', function(event) {
    ga_send_event($(this).data('ga-click-event'));
  });

  $('#nav-Login').on('click', function() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'nav-bar', 'login');
    }
  });

  $('.nav-Join').on('click', function() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'nav-bar', 'join');
    }
  });

  $('.plugin-download-button').on('click', function() {
    ga_download_plugin('header');
  });

  $('.get_the_plugin').on('click', function() {
    ga_download_plugin('install');
  });

  $('.js-related-objects').on('click', function(event) {
    ga_related_fg($(this).data('related-feature'));
  });

  $('.js-related-object').on('click', function(event) {
    ga_related_product($(this).data('relatedProduct'));
  });

  $('.js-related-theme').on('click', function(event) {
    ga_related_theme($(this).data('theme'));
  });

  $('.js-category').on('click', function(event) {
    ga_category($(this).data('category'));
  });

  // homepage
  $('.js-hp-search-more-objects').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'search-more-objects', gon.homepage_type);
    }
  });

  $('.js-hp-sign-up-now').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'intro-sign-up-now', gon.homepage_type);
    }
  });

  $('.js-hp-view-all-content-header').on('click', function(event) {
    ga_homepage_view_all_content('header-' + gon.homepage_type)
  });

  $('.js-hp-cta-panel-join-now').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'cta-panel', 'join');
    }
  });

  $('.js-hp-mobile-cta-explore-content').on('click', function(event) {
    ga_homepage_view_all_content('mobile-cta-' + gon.homepage_type)
  });

  $('.js-hp-view-all-content-downloads').on('click', function(event) {
    ga_homepage_view_all_content('downloads-panel-' + gon.homepage_type)
  });

  $('.js-hp-view-all-content-intro').on('click', function(event) {
    ga_homepage_view_all_content('intro-panel-' + gon.homepage_type)
  });

  $('.js-hp-workflow-cta').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'home-workflow-' + gon.homepage_type, 'plugin-demo');
    }
  });

  $('#js-add-free-lightbox').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'free-lightbox-download', 'clone-lightbox')
    }
  });

  $('#js-add-free-lightbox-mobile').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'free-lightbox-download', 'clone-lightbox-mobile')
    }
  });

  $('#js-pricing-plan-toggle-switch').on('click', function(event) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'pricing', 'pricing-toggle-click')
    }
  });

  // plugin demo page
  $('.mac-installer').on('click', function() {
    analytics_plugin_demo_osx_installer();
  });

  $('.windows-installer').on('click', function() {
    analytics_plugin_demo_windows_installer();
  });

  $('.adobe-installer').on('click', function() {
    analytics_plugin_demo_adobe_installer();
  });

  $('.link-asset').on('click', function() {
    if (typeof ga !== 'undefined') {
      ga('ec:addProduct', {
        'id': $(this).data('support-id')
      });

      ga('ec:setAction', 'click', {
        list: 'Search Results'
      });

      ga('send', 'event', 'UX', 'click', 'Results');
    }

    return true;
  });
});
