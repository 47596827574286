Q = require('q/q')
import { GalleryItem } from './gallery-item'
import { ImageCache } from '../application/image-cache'
import { SpinnerPreview } from './spinner-preview'
import { SpriteSpinnerPreview } from './sprite-spinner-preview'
import { QuickPreview } from './quick-preview'

export class PixelsquidQuickPreview
  DEFAULT_OPTIONS =
    selectors:
      itemContainerSelector: '.search-results-container'
      itemSelector: '.ts-gallery-column'
      spriteSpinnerPreviewSelector: '.js-spinner-sprite-display'
      spriteSpinnerPreviewLinkSelector: '.js-quick-preview-overlay-content .js-quick-preview-image-content[data-image-type="atlas-sprite"] a'
      quickPreviewImagesSelector: '#js-quick-preview-overlay img, #js-quick-preview-popup .js-quick-preview-image-content:lt(2) img'
      quickPreviewOverlayIdSelector: '#js-quick-preview-overlay'
      quickPreviewPopupIdSelector: '#js-quick-preview-popup'
      arrowLeftSelector: '.js-quick-preview-overlay-arrows-left'
      arrowRightSelector: '.js-quick-preview-overlay-arrows-right'
      quickPreviewImagesContainerSelector: '.js-quick-preview-images .js-quick-preview-image-content'
      cachedImageSelector: '.js-quick-preview-cached-img'
    spriteSpinnerPreview:
      imageWidth: 600

  constructor: (userOptions = {})->
    @options = Object.assign(DEFAULT_OPTIONS, userOptions)

    @currentImageIndex = 0
    @imageCache = new ImageCache()
    @galleryItems = {}

    spinnerPreviewContainerSelector = '#js-quick-preview-overlay'
    @overlaySpinnerPreview = new SpinnerPreview(containerSelector: spinnerPreviewContainerSelector)
    @overlaySpriteSpinnerPreview = new SpriteSpinnerPreview(containerSelector: spinnerPreviewContainerSelector)

    quickPreviewOptions = {
      beforeShow: @beforeShow
      afterShow: @afterShow
      beforeHide: @beforeHide
      afterHide: @afterHide
      itemContainerSelector: @options.selectors.itemContainerSelector
      itemSelector: @options.selectors.itemSelector
      disablePopup: true
    }

    @qp = new QuickPreview(quickPreviewOptions)
    @qp.quickPreviewOverlay.$quickPreviewOverlay.on 'click', @options.selectors.arrowLeftSelector, @showPrevious
    @qp.quickPreviewOverlay.$quickPreviewOverlay.on 'click', @options.selectors.arrowRightSelector, @showNext

  beforeShow: ($element)=>
    @cacheGalleryItem($element)
    .then(@fetchData)
    .then(@setSpriteSpinnerPreviewStyle)
    .then(@setOverlayHeight($element))
    .then => Q($element)

  afterShow: =>
    @cacheInitialHighResolutionImages().then(@setOverlayHeight)

  cacheInitialHighResolutionImages: =>
    images = $(@options.selectors.quickPreviewImagesSelector)
    sprites = @qp.quickPreviewOverlay.$quickPreviewOverlay.find(@options.selectors.spriteSpinnerPreviewSelector)

    Q.all([@cacheImages(images), @cacheSprites(sprites)]).then =>
      @showImageAt(@currentImageIndex)

    return

  beforeHide: =>
    @currentImageIndex = 0
    @overlaySpriteSpinnerPreview.stop()
    @overlaySpinnerPreview.stop()
    Q()

  afterHide: =>
    @resetOverlayHeight()

  showNext: =>
    @currentImageIndex = (@currentImageIndex + 1) % @$quickPreviewImages().length
    @showImageAt(@currentImageIndex)

  showPrevious: =>
    length = @$quickPreviewImages().length
    @currentImageIndex = (@currentImageIndex + (length - 1)) % length
    @showImageAt(@currentImageIndex)

  showImageAt: (index)=>
    $currentImage = $(@$quickPreviewImages()[index])

    @qp.quickPreviewOverlay.$quickPreviewOverlay.find(@options.selectors.cachedImageSelector).removeClass('active')

    @$quickPreviewImages().removeClass('active')
    $currentImage.addClass('active')
    @qp.quickPreviewOverlay.$quickPreviewOverlay.find('a.link-asset')[0].href = $currentImage.find('a')[0].href

    switch $currentImage.data('imageType')
      when 'atlas'
        @overlaySpinnerPreview.start()
      when 'atlas-sprite'
        @overlaySpriteSpinnerPreview.start()
      else
        @overlaySpriteSpinnerPreview.stop()
        @overlaySpinnerPreview.stop()

  cacheSprites: (sprites)->
    return Q() unless sprites?.length

    addImageToBody = (index, element)->
      $sprite = $(element)
      image = new Image()
      $image = $(image)
      $image.on 'load', -> $(@).remove()
      $image.attr('src', $sprite.data('url'))
      $image.css('display', 'none')
      $image.appendTo('body')
      image

    ImageCache.cacheImages(sprites.map(addImageToBody))

  cacheImages: (images)->
    return Q() unless images?.length
    ImageCache.cacheImages(images)

  cacheGalleryItem: ($element)=>
    @galleryItem = @galleryItems[$element.data('id')] ?= new GalleryItem($element)
    Q(@galleryItem)

  setSpriteSpinnerPreviewStyle: ($element)=>
    sprites = $element.find(@options.selectors.spriteSpinnerPreviewSelector)
    if sprites.length
      $sprite = $(sprites[0])
      width = $element.outerWidth()
      scale = width / @options.spriteSpinnerPreview.imageWidth

      if Modernizr.csstransforms
        spritesContainer = $element.find(@options.selectors.spriteSpinnerPreviewLinkSelector)
        spritesContainer.css(width: width, height: width, display: 'block')
        style = {
          'position': 'absolute'
          'transform': "scale(#{scale})"
          'transform-origin': 'top left'
          '-moz-transform': "scale(#{scale})"
          '-moz-transform-origin': 'top left'
        }
      else
        style = { zoom: scale }

      $sprite.css(style)

    Q($element)

  setOverlayHeight: ($element)=>
    $btn = $element.find('.btn-add-to-lightbox')

    if $btn.hasClass('expanded')
      current_height = parseInt($('#js-quick-preview-overlay').height())
      $('#js-quick-preview-overlay').css('height', current_height + 210)

  resetOverlayHeight: =>
    $('#js-quick-preview-overlay').css('height', 'initial')

  fetchData: (galleryItem)=>
    galleryItem.fetchQuickPreview()

  $quickPreviewImages: =>
    $imageContainer = @qp.quickPreviewOverlay.$quickPreviewOverlay
    $imageContainer.find(@options.selectors.quickPreviewImagesContainerSelector)

  removeGalleryItems: ({excepted_ids})->
    for product_id of @galleryItems
      if product_id not in excepted_ids
        $(".ts-gallery-column[data-id=#{product_id}]").find('.js-quick-preview-overlay-content').remove()
        delete(@galleryItems[product_id])
